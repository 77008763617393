body {
  font-family: "Montserrat Regular" !important;
  background-color: #000000;
  /* background-image: linear-gradient( 
270deg
, #000000 0%, #000c23 74%); */
  background-image: linear-gradient(to right, #000c23, #022158, #03286b, #000c23);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.rotate {
  animation: rotation 10s infinite linear;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: calc((100vw - 250px) / 2);
  height: 250px;
}

.apexcharts-legend-text {
  color: white;
}
