.App {
  width: 100%;
  min-height: 100vh;
  font-size: small;
  display: flex;
  flex-direction: column-reverse;
  position: relative;
}

.App:before {
  content: "";
  background-image: url("./images/logo.png");
  background-size: 50%;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.2;
  background-position: center;
  background-repeat: no-repeat;
}

.lock {
  width: 100%;
  font-size: small;
  min-height: 287px;
  display: flex;
  flex-direction: column-reverse;
  position: relative;
}

.lock:before {
  content: "";
  background-image: url("./images/lock.png");
  background-size: 50%;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.9;
  background-position: center;
  background-repeat: no-repeat;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.content {
  top: 0;
  position: absolute;
  width: 100%;
}

#log {
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  margin: 10px;
  padding-left: 40px;
  height: 90px;
  overflow-y: scroll;
  font-family: "Montserrat",sans-serif;
  line-height: 24px;
}

.link {
  color: yellow;
}

ul.details {
  width: 100%;
}

ul.details li {
  list-style: none;
  display: flex;
  justify-content: space-between;
  padding: 8px;
  font-family: "Montserrat",sans-serif;
  list-style: none;
}

ul.details li b {
  width: 50%;
}

.time {
  font-size: 32px;
}


.AppTimer {
  display: flex;
  justify-content: space-around;
  font-family: "Montserrat",sans-serif;
  text-align: center;
}

.MuiAlert-standardSuccess{
  color: white !important;
  background-color: rgba(0,255,0,0.5) !important;
}